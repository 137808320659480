import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useEffect } from "react";
import { Row , Col , Button, Badge  } from 'react-bootstrap';
import AnchorLink from "react-anchor-link-smooth-scroll";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { getSecteurs } from "services/secteursService";
import { useMutation } from "@tanstack/react-query";
import { getServices } from "services/servicesService";
import { BASE_URL_Image } from "services/server";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; 
import Pagination from '@mui/material/Pagination';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
    import PerfectScrollbar from 'react-perfect-scrollbar'

import Hammer from 'hammerjs';
// Import Swiper styles
import 'swiper/css';

import HorizontalScroll from "react-scroll-horizontal";
import "./serviceSlider.css" ; 
import { Close } from '@mui/icons-material';
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { getArticles } from 'services/blogService';
import { Box, CircularProgress } from '@mui/material';


const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const MainContainer = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto `;

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;


const CardTW = tw.div`h-full flex! flex-col  max-w-sm  relative focus:outline-none bg-white`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;


const RowTw = tw.div`flex flex-col lg:flex-row -mb-10`;
const Heading = tw(SectionHeading)`text-left lg:text-4xl xl:text-5xl`;

const PopularPostsContainer = tw.div`lg:w-2/3`;
const PostsContainer = tw.div`mt-12 flex flex-col sm:flex-row sm:justify-between lg:justify-start`;
const Post = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`
]);
const TitleTw = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`;
const DescriptionTw = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;
const AuthorInfo = tw.div`mt-6 flex items-center`;
const AuthorImage = tw.img`w-12 h-12 rounded-full`;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-lg`;
const AuthorProfile = tw.p`text-secondary-100 text-sm`;

const RecentPostsContainer = styled.div`
  ${tw`mt-24 lg:mt-0 lg:w-1/3`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-10 max-w-none w-full sm:w-1/2 lg:w-auto sm:odd:pr-12 lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw`mt-3 text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-20 w-20 flex-shrink-0`}
  }
`;
const PostTextContainer = tw.div``


export default () => {
  
  const { id } = useParams();

  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%"
    },
    hover: {
      backgroundSize: "110%"
    }
  };
  
  const lang = useSelector((state) => state.lang.value) ;

  //Recommended: Only 2 Items
  const [popularPosts, setPopularPosts] = useState([]);

  const [recentPosts, setRecentPosts] = useState([])



  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [sliderHammerRef, setSliderHammerRef] = useState(null);
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [page, setPage] = useState({
    index : 1 , 
    limit : 12 , 
    totalItems : 0 , 
  });

  useEffect(()=> {
    const intervalId = setInterval(() => {
        if(sliderRef) {
            sliderRef?.slickNext() ;
        }
    } , 3000) ; 
    return () => {
        clearInterval(intervalId);
    } ;
  } , [sliderRef]) ;

  
  useEffect(()=> {
    const element = sliderHammerRef?.current ;
    if(element) {
      const hammer = new Hammer(element);
      hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });
      hammer.on('pan', this.handlePan);
    }
  } , [sliderHammerRef]) ;
  

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };
  
  const [items, setItems] = useState([]) ; 
  const [showFilter, setShowfilter] = useState(false) ; 
 
  
  const { mutate : mutateArticles, isLoading : isLoadingServices } = useMutation(
    (filters) => getArticles(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          setPopularPosts(data.data.map((el) => { 
            if(!el.image) {return el ;}  
            return {...el , image : BASE_URL_Image+el.image} ;
          }).sort((a, b) => {
            if (a.image !== null && b.image === null) {
              return -1; // a comes first
            } else if (a.image === null && b.image !== null) {
              return 1; // b comes first
            }
            return 0; // both have image or both are null
          }) ) ; 
          
          
          setPage(oldValue => {return {...oldValue , totalItems : parseInt(data.total)}}) ; 
          let table_items =  [] ; 
          // if(parseInt(data.total) !== 0) {
          //   for (let number = 1; number <= parseInt(parseInt(data.total)/12); number++) {
          //     table_items.push(
          //       <Pagination.Item key={number} active={number === page.index} onClick={()=> {setPage(oldValue => {return {...oldValue , index : number}})}}
          //           className='mx-2' style={{borderRadius: "20%" ,overflow: "hidden" , border : "1px solid #b7b7b7"}}
          //       >
          //         {number}
          //       </Pagination.Item>,
          //     );
          //   }
          // }
          setItems(table_items) ;
        }
      },
      onError(error) {
        
      },
    }
  );

  const [categories, setCategorie] = useState([
    {id : 1  , name :"Technologie"} ,
    {id : 2  , name :"Informatique"} ,
    {id : 3  , name :"Physique"} ,
    {id : 4  , name :"Médecine & Bio"} ,
    {id : 5  , name :"Société & Comportement"} ,
    {id : 6  , name :"Espace & Astrophysique"} ,
    {id : 7  , name :"Planète & Environnement"} ,
    {id : 8  , name :"Nature"} ,
    {id : 9  , name :"Animaux"}  ,
    {id : 10  , name :"Entreprises"}  ,
    {id : 11  , name :"Gouvernement"}  ,
    {id : 12  , name :"Investissement"}  ,
    {id : 13  , name :"Regulateurs"}  ,
    {id : 14  , name :"Finance Durable"}  
  ]) ;
  


  const { mutate : mutateSecteurs, isLoading : isLoadingSecteurs } = useMutation(
    () => getSecteurs(),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          setCategorie(
            data.data.map((el) => {
              return {
                  id : el.id , 
                  text : el.nom
              } 
            })
            ) ; 
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

  useEffect(()=> {
    console.log("loading") ;
    // mutateSecteurs() ;
    mutateArticles({categorie_id : id , filter : search ,  limit : 12 , offset : (page.index -1)*12 }) ; 
  } , []) ;

  useEffect(()=> {
    console.log("loading") ;
    // mutateSecteurs() ;
    mutateArticles({categorie_id : id , filter : search ,  limit : 12 , offset : (page.index -1)*12 }) ; 
  } , [id]) ;

  
  useEffect(()=> {
      mutateArticles({categorie_id : id ,filter : search , limit : 12 , offset : (page.index -1)*12  }) ; 
  } , [page.index]) ;

  
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
  
  
  

  
  
  return (
    
  <AnimationRevealPage>
    <MainContainer style={{marginTop : "80px"}}>
      <Content>
        {id && 
          <Row className='mb-3'>
              <Col xs={12}>
                <MDTypography>
                  <strong>{categories.filter(el => el.id === parseInt(id))[0].name }</strong>
                </MDTypography>
              </Col>
          </Row>
        }
        <Row>
          <Col xs={12}>
            <ContentWithPaddingXl style={{paddingTop : "0"}}>
              <RowTw>
                  <Row className='justify-content-start ml-0 p-2'>
                    {popularPosts.length > 0 &&
                      <Col xs={12} className="d-flex justify-content-center align-items-center my-3">
                        {/* <Pagination>{items}</Pagination> */}
                        <Pagination count={(parseInt(page.totalItems)%12 == 0 ? parseInt(parseInt(page.totalItems)/12) : parseInt(parseInt(page.totalItems)/12)+1)} page={page.index} onChange={(event, value)=> {setPage(oldValue => {return {...oldValue , index : value}})}} />
                      </Col>
                        
                      }

                    {popularPosts.map((post, index) => (
                      <Col key={index} href={post.url} className="group col-12 col-md-6 col-lg-4  mb-5 "  initial="rest" whileHover="hover" animate="rest"
                        
                      >
                        <div style={{width : "90%"}}>
                          <Image
                            transition={{ duration: 0.3 }}
                            variants={postBackgroundSizeAnimation}
                            $imageSrc={post.image}
                          />
                        </div>
                        <div className='p-2 bg-white'  style={{ width : "90%" , marginLeft : "10%" , marginTop : "-30px"}}>
                          
                        <AuthorInfo style={{marginTop : "0"}}>
                          <div className='w-100 d-flex justify-content-between align-items-center' style={{ paddingTop: '0'}}>
                            <AuthorProfile style={{cursor : "pointer" , textDecoration : "underline" , color : "rgb(1 192 130)" , fontWeight : "bold"}}    onClick={() => {
                              if(lang === "en") {
                                navigate("/en/categorie/"+post.categorie_id) ;
                              }else if(lang == "ar") {
                                navigate("/ar/categorie/"+post.categorie_id) ;
                              }else {
                                navigate("/fr/categorie/"+post.categorie_id) ;
                              }
                              }}>{categories.filter(el => el.id === parseInt(post.categorie_id))[0].name }</AuthorProfile>
                            <AuthorProfile>{post.date}</AuthorProfile>
                          </div>
                        </AuthorInfo>
                        <TitleTw  onClick={() => {
                          if(lang === "en") {
                            navigate("/en/article/"+post.id) ;
                          }else if(lang == "ar") {
                            navigate("/ar/article/"+post.id) ;
                          }else {
                            navigate("/fr/article/"+post.id) ;
                          }
                          }} style={{cursor : "pointer" , textAlign : "center" , paddingBottom: '15px' , marginTop : "0"}}>{lang === "fr" ? post.reformulated_title : lang === "en" ? post.reformulated_title_en : post.reformulated_title_ar }</TitleTw>
                        {/* <DescriptionTw style={{maxHeight: "300px" , overflow: "hidden" , textOverflow: "ellipsis"}}>{lang === "fr" ? post.reformulated_description : lang === "en" ? post.reformulated_description_en : post.reformulated_description_ar}</DescriptionTw> */}
                        {/* <div className='w-100 d-flex align-items-center justify-content-center'>
                          <strong style={{width : "100%" , textAlign : "center" , fontSize : "13px"}}>
                          {lang === "fr" ? "La redaction " : lang === "en" ? " Writing " :  " التحرير"}
                            </strong>
                        </div> */}

                        </div>
                      </Col>
                    ))}
                    {popularPosts.length > 0 &&
                      <Col xs={12} className="d-flex justify-content-center align-items-center my-3">
                        {/* <Pagination>{items}</Pagination> */}
                        <Pagination count={(parseInt(page.totalItems)%12 == 0 ? parseInt(parseInt(page.totalItems)/12) : parseInt(parseInt(page.totalItems)/12)+1)} page={page.index} onChange={(event, value)=> {setPage(oldValue => {return {...oldValue , index : value}})}} />
                      </Col>
                        
                      }
                    {page.totalItems == 0 && 
                      <Col xs={12} className="d-flex justify-content-center align-items-center" style={{minHeigth : "70vh"}}>
                            <MDTypography variant="h6" color="grey">
                            Aucun Article
                            </MDTypography>
                        
                      </Col>
                    
                    }
                  </Row>
              </RowTw>
            </ContentWithPaddingXl>
          </Col>
          
        </Row>
        </Content>
    </MainContainer>

    
    <Modal
      show={isLoadingSecteurs || isLoadingServices}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body  style={{background : "transparent"}}>
        
      <Box sx={{ display: 'flex' }} className="justify-content-center align-items-center">
        <CircularProgress color='black' />
      </Box>
      </Modal.Body>
    </Modal>

  </AnimationRevealPage>
  );
};
