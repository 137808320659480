import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useEffect } from "react";
import { Row, Col, Pagination, Button, Badge } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { getSecteurs } from "services/secteursService";
import { useMutation } from "@tanstack/react-query";
import { getServices } from "services/servicesService";
import { BASE_URL_Image } from "services/server";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
// import { NextSeo } from 'next-seo';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import PerfectScrollbar from "react-perfect-scrollbar";

import Hammer from "hammerjs";
// Import Swiper styles
import "swiper/css";

import HorizontalScroll from "react-scroll-horizontal";
import "./serviceSlider.css";
import { Close } from "@mui/icons-material";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { getArticles } from "services/blogService";
import { Box, CircularProgress } from "@mui/material";

const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const MainContainer = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto `;

const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const CardTW = tw.div`h-full flex! flex-col  max-w-sm  relative focus:outline-none bg-white`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const RowTw = tw.div`flex flex-col lg:flex-row -mb-10`;
const Heading = tw(SectionHeading)`text-left lg:text-4xl xl:text-5xl`;

const PopularPostsContainer = tw.div`lg:w-2/3`;
const PostsContainer = tw.div`mt-12 flex flex-col sm:flex-row sm:justify-between lg:justify-start`;
const Post = tw(
  motion.a
)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)((props) => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`,
]);
const TitleTw = tw.h5`mt-6 text-xl font-bold `;
const DescriptionTw = tw.div`mt-2 font-medium text-secondary-100 leading-loose text-sm`;
const AuthorInfo = tw.div`mt-6 flex items-center`;
const AuthorImage = tw.img`w-12 h-12 rounded-full`;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-lg`;
const AuthorProfile = tw.p`text-secondary-100 text-sm`;

const RecentPostsContainer = styled.div`
  ${tw`mt-24 lg:mt-0 lg:w-1/3`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-10 max-w-none w-full sm:w-1/2 lg:w-auto sm:odd:pr-12 lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw`mt-3 text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-20 w-20 flex-shrink-0`}
  }
`;
const PostTextContainer = tw.div``;

export default () => {
  const { id } = useParams();

  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%",
    },
    hover: {
      backgroundSize: "110%",
    },
  };

  const lang = useSelector((state) => state.lang.value);

  //Recommended: Only 2 Items
  const [popularPosts, setPopularPosts] = useState([]);

  const [recentPosts, setRecentPosts] = useState([]);

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [sliderHammerRef, setSliderHammerRef] = useState(null);
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [page, setPage] = useState({
    index: 1,
    limit: 5,
    totalItems: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sliderRef) {
        sliderRef?.slickNext();
      }
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sliderRef]);

  useEffect(() => {
    const element = sliderHammerRef?.current;
    if (element) {
      const hammer = new Hammer(element);
      hammer.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });
      hammer.on("pan", this.handlePan);
    }
  }, [sliderHammerRef]);

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [items, setItems] = useState([]);
  const [showFilter, setShowfilter] = useState(false);

  const { mutate: mutateArticles, isLoading: isLoadingServices } = useMutation(
    (filters) => getArticles(filters),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setPopularPosts(
            data.data.map((el) => {
              if (!el.image) {
                return el;
              }
              return { ...el, image: BASE_URL_Image + el.image };
            })
          );
        }
      },
      onError(error) {},
    }
  );

  const [categories, setCategorie] = useState([
    { id: 1, name: "Technologie" },
    { id: 2, name: "Informatique" },
    { id: 3, name: "Physique" },
    { id: 4, name: "Médecine & Bio" },
    { id: 5, name: "Société & Comportement" },
    { id: 6, name: "Espace & Astrophysique" },
    { id: 7, name: "Planète & Environnement" },
    { id: 8, name: "Nature" },
    { id: 9, name: "Animaux" },
    { id: 10, name: "Entreprises" },
    { id: 11, name: "Gouvernement" },
    { id: 12, name: "Investissement" },
    { id: 13, name: "Regulateurs" },
    { id: 14, name: "Finance Durable" },
  ]);

  const { mutate: mutateSecteurs, isLoading: isLoadingSecteurs } = useMutation(
    () => getSecteurs(),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data) {
          setCategorie(
            data.data.map((el) => {
              return {
                id: el.id,
                text: el.nom,
              };
            })
          );
        } else {
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    console.log("loading");
    // mutateSecteurs() ;
    mutateArticles({ article_id: id, filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, []);

  useEffect(() => {
    console.log("loading");
    // mutateSecteurs() ;
    mutateArticles({ article_id: id, filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, [id]);

  useEffect(() => {
    mutateArticles({ article_id: id, filter: search, limit: 5, offset: (page.index - 1) * 5 });
  }, [page.index]);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }

  return (
    <AnimationRevealPage>
      <MainContainer style={{ marginTop: "80px" }}>
        <Content>
          <Helmet>
            {popularPosts.length > 0 && <title>{popularPosts[0].reformulated_title}</title>}
            <meta
              property="og:image"
              content={
                "https://algeriachannel.com/ws/public/storage/images/articles/" + id + ".jpg"
              }
            />
          </Helmet>

          {/* <NextSeo
            title={popularPosts.length > 0 ? popularPosts[0].reformulated_title : ""}
            openGraph={{
              title: popularPosts.length > 0 ? popularPosts[0].reformulated_title : "",
              images: [
                {
                  url: "https://algeriachannel.com/ws/public/storage/images/articles/"+id+".jpg",
                  alt: "article image",
                },
              ],
            }}
          /> */}

          <Row>
            <Col xs={12}>
              <ContentWithPaddingXl style={{ paddingTop: "0" }}>
                <RowTw>
                  <Row className="justify-content-start ml-0 p-2">
                    {popularPosts.map((post, index) => (
                      <Col key={index} className="group col-12">
                        <div
                          className=" bg-white"
                          style={{ padding: "10px 25px ", borderRadius: "15px" }}
                        >
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <AuthorInfo style={{ margin: "0" }}>
                              <div className="w-100 d-flex justify-content-start align-items-center">
                                <AuthorProfile
                                  style={{
                                    margin: "0",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "rgb(1 192 130)",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => {
                                    if (lang === "en") {
                                      navigate("/en/categorie/" + post.categorie_id);
                                    } else if (lang == "ar") {
                                      navigate("/ar/categorie/" + post.categorie_id);
                                    } else {
                                      navigate("/fr/categorie/" + post.categorie_id);
                                    }
                                  }}
                                >
                                  {
                                    categories.filter(
                                      (el) => el.id === parseInt(post.categorie_id)
                                    )[0].name
                                  }
                                </AuthorProfile>
                              </div>
                            </AuthorInfo>
                            <AuthorProfile style={{ margin: "0" }}>{post.date}</AuthorProfile>
                          </div>
                        </div>
                        <MDTypography
                          style={{
                            fontWeight: "bold",
                            marginTop: "20px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          {lang === "fr"
                            ? post.reformulated_title
                            : lang === "en"
                            ? post.reformulated_title_en
                            : post.reformulated_title_ar}
                        </MDTypography>

                        {post.image != null && (
                          <img
                            alt=""
                            src={post.image}
                            style={{ width: "100%", height: "auto", borderRadius: "20px" }}
                          />
                        )}
                        <div
                          className="p-3 bg-white"
                          style={{ marginTop: "40px", padding: "10px 25px ", borderRadius: "15px" }}
                        >
                          <DescriptionTw
                            style={{
                              direction: lang === "ar" ? "rtl" : "ltr",
                              textAlign: lang === "ar" ? "right" : "left",
                            }}
                          >
                            {lang === "fr" ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: post.reformulated_description.replace(/\n/g, "<br/>"),
                                }}
                              />
                            ) : lang === "en" ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: post.reformulated_description_en.replace(/\n/g, "<br/>"),
                                }}
                              />
                            ) : (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: post.reformulated_description_ar.replace(/\n/g, "<br/>"),
                                }}
                              />
                            )}
                          </DescriptionTw>
                          <div className="w-100 d-flex align-items-center justify-content-center">
                            <strong
                              style={{ width: "100%", textAlign: "center", fontSize: "13px" }}
                            >
                              {lang === "fr"
                                ? "La redaction "
                                : lang === "en"
                                ? " Writing "
                                : " التحرير"}
                            </strong>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </RowTw>
              </ContentWithPaddingXl>
            </Col>
          </Row>
        </Content>
      </MainContainer>

      <Modal
        show={isLoadingSecteurs || isLoadingServices}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ background: "transparent" }}>
          <Box sx={{ display: "flex" }} className="justify-content-center align-items-center">
            <CircularProgress color="black" />
          </Box>
        </Modal.Body>
      </Modal>
    </AnimationRevealPage>
  );
};
