import { authApi } from "./server";



export const getArticles = async (userInfos) => {
    const response = await authApi.post('getArticles' , userInfos);
    return response.data;
};



export const storeArticles = async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('categorie_id', userInfo.categorie_id);
    formData.append('date', userInfo.date);
    formData.append('reformulated_title', userInfo.reformulated_title);
    formData.append('reformulated_title_en', userInfo.reformulated_title_en);
    formData.append('reformulated_title_ar', userInfo.reformulated_title_ar);
    formData.append('reformulated_description', userInfo.reformulated_description);
    formData.append('reformulated_description_en', userInfo.reformulated_description_en);
    formData.append('reformulated_description_ar', userInfo.reformulated_description_ar);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('admin/storeArticle' , formData);
    return response.data;
};


export const deleteArticle = async (userinfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('admin/deleteArticle' , userinfo);
    return response.data;
};