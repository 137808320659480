
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layoutsGroupes/PartenaireList/components/List/data";
import { Box, CircularProgress, Divider, FormControl, Grid, Input, InputLabel, MenuItem, Select } from "@mui/material";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import MDButton from "components/MDButton";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import MDInput from "components/MDInput";
import React, { useRef, useState } from "react";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { useEffect } from "react";
import { Row , Col , Dropdown, Badge  } from 'react-bootstrap';
import { BASE_URL_Image } from "services/server";
import { getServices } from "services/adminService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { updateService } from "services/adminService";
import { toast } from "react-toastify";
import { uploaderSercteur } from "services/adminService";
import { getSecteurs } from "services/adminService";
import { getArticles } from "services/blogService";
import { SectionHeading } from "components/misc/Headings";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { storeArticles } from "services/blogService";
  import { deleteArticle } from "services/blogService";
  import Pagination from '@mui/material/Pagination';
import HorizontalScroll from "react-scroll-horizontal";

const CardPub = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none bg-white m-0`;

const ActionButton = tw(
  Button
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal border-none`;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const Container = tw.div`relative w-full`;


const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full py-3 sm:py-6 bg-gray-500 text-gray-100 border-none`;


const CardTW = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");height : 100% ; `,
  tw`w-full  bg-cover bg-center rounded sm:rounded-none`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;

const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;







const RowTw = tw.div`flex flex-col lg:flex-row -mb-10`;
const Heading = tw(SectionHeading)`text-left lg:text-4xl xl:text-5xl`;

const PopularPostsContainer = tw.div`lg:w-2/3`;
const PostsContainer = tw.div`mt-12 flex flex-col sm:flex-row sm:justify-between lg:justify-start`;
const Post = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`
]);
const TitleTw = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`;
const DescriptionTw = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;
const AuthorInfo = tw.div`mt-6 flex items-center`;
const AuthorImage = tw.img`w-12 h-12 rounded-full`;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-lg`;
const AuthorProfile = tw.p`text-secondary-100 text-sm`;


function List() {
  const [menu, setMenu] = useState(null);  
  const [search, setSearch] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [groupe, setGroupe] = useState("");

  const [publireportage, setPublireportage] = useState(null);
  const navigate = useNavigate();

  const lang = useSelector((state) => state.lang.value) ;

  const [categories, setCategorie] = useState([
    {id : 1  , name :"Technologie"} ,
    {id : 2  , name :"Informatique"} ,
    {id : 3  , name :"Physique"} ,
    {id : 4  , name :"Médecine & Bio"} ,
    {id : 5  , name :"Société & Comportement"} ,
    {id : 6  , name :"Espace & Astrophysique"} ,
    {id : 7  , name :"Planète & Environnement"} ,
    {id : 8  , name :"Nature"} ,
    {id : 9  , name :"Animaux"}  ,
    {id : 10  , name :"Entreprises"}  ,
    {id : 11  , name :"Gouvernement"}  ,
    {id : 12  , name :"Investissement"}  ,
    {id : 13  , name :"Regulateurs"}  ,
    {id : 14  , name :"Finance Durable"}  
  ]) ;
  


  const [onUpdateSecteur, setOnUpdateSecteur] = useState(null);

  const [page, setPage] = useState({
    index : 1 , 
    limit : 12 , 
    totalItems : 0 , 
  });
  

  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%"
    },
    hover: {
      backgroundSize: "110%"
    }
  };
  
  const [secteurs, setSecteurs] = useState([]);
  const [activeSecteur, setActiveSecteur] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [items, setItems] = useState([]);
  const [services, setServices] = useState([]) ; 

  const { mutate, isLoading } = useMutation(
    (userInfo) => getSecteurs(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          setSecteurs(
            data.data.filter(el => el.status !== 1 ).map((el) => {
              return {
                  imageSrc : BASE_URL_Image+el.image , 
                  id : el.id , 
                  title : el.nom , 
                  status : parseInt(el.status) === 1 
              } 
            })
            ) ; 
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

  useEffect(()=> {
    mutate({ filter : search ,limit : 5 , offset : (page.index -1)*5 }) ; 
  } , []) ;

  const [selectedCategorie, setSelectedCategorie] = useState(1);
  const [nomService, setNomService] = useState("");
  const [description, setDescription] = useState("");
  const [nomServiceEn, setNomServiceEn] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [nomServiceAr, setNomServiceAr] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");


  const [pictureUrl, setPictureUrl] = useState('');
  
  const [selectedFile, setSelectedFile] = useState(null);  
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  
  useEffect(()=> {
    console.log(selectedFile);
    if(selectedFile) {
        let url = URL.createObjectURL(selectedFile) ;
        if(url) {
            console.log(url) ;
            setPictureUrl(url) ;
        }
    }
  } , [selectedFile]) ;

  const insertNewSecteur = () => {
    if(!selectedFile || !pictureUrl || pictureUrl === "") {
      notify("Vous devez inserer une image") ;
    }else if(selectedCategorie === null) {
      notify("Vous devez selectionner une categorie") ;
    }else if(nomService === "") {
      notify("Vous devez entrez un titre") ;
    }else if(description === "") {
      notify("Vous devez entrez une description") ;
    }else if(nomServiceEn === "") {
      notify("Vous devez entrez un titre en") ;
    }else if(descriptionEn === "") {
      notify("Vous devez entrez une description en") ;
    }else if(nomServiceAr === "") {
      notify("Vous devez entrez un titre ar") ;
    }else if(descriptionAr === "") {
      notify("Vous devez entrez une description ar") ;
    }else {
      let today = new Date();
      let day = String(today.getDate()).padStart(2, '0');
      let month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      let year = today.getFullYear();

      let formattedDate = `${day}-${month}-${year}`;
      mutateNewSecteur({
        file : selectedFile , 
        categorie_id : selectedCategorie ,
        reformulated_title : nomService ,
        date : formattedDate ,
        title : nomServiceEn ,
        description : "" ,
        reformulated_description : description ,
        reformulated_title_en : nomServiceEn ,
        reformulated_description_en : descriptionEn ,
        reformulated_title_ar : nomServiceAr ,
        reformulated_description_ar : descriptionAr ,
      }) ; 
      // setOpenAdd(false);
    }
  } 

  const modifierSecteur = (id) => {
    if(!selectedFile && pictureUrl !== "" && nomService != "") {
      // mutateUpdateSecteur({id : onUpdateSecteur , nom : nomService }) ; 
    } else if(nomService === "") {
      notify("Vous devez entrez un nom") ;
    }else  if(pictureUrl === "") {
      notify("Vous devez inserer une image") ;
    } else {
      // mutateUpdateSecteur({id : onUpdateSecteur ,file : selectedFile , nom : nomService }) ; 
      // setOpenAdd(false);
    }
  } 
  const { mutate : mutateNewSecteur, isLoading : isLoadingNewSecteur } = useMutation(
    (newArticle) => storeArticles(newArticle),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.success) {
          notify_success("Ajouter avec succès") ;
          handleCloseAdd() ; 
          mutateArticles({admin : true , filter : search ,limit : 12 , offset : (page.index -1)*12 }) ; 
        }else { 
          notify("Une erreur a été produit") ;
        }
      },
      onError(error) {
        
      },
    }
  );
  const { mutate : mutateArticles, isLoading : isLoadingServices } = useMutation(
    (filters) => getArticles(filters),
    {
      
      async onSuccess(data) {
        console.log(data);
        if(data && data.data) {
          setServices(data.data.map((el) => { 
            if(!el.image) {return el ;}  
            return {...el , image : BASE_URL_Image+el.image} ;
          }).sort((a, b) => {
            if (a.image !== null && b.image === null) {
              return -1; // a comes first
            } else if (a.image === null && b.image !== null) {
              return 1; // b comes first
            }
            return 0; // both have image or both are null
          }) ) ; 
          
          
          setPage(oldValue => {return {...oldValue , totalItems : parseInt(data.total)}}) ; 
          let table_items =  [] ; 
          // if(parseInt(data.total) !== 0) {
          //   for (let number = 1; number <= (parseInt(data.total)%12 == 0 ? parseInt(parseInt(data.total)/12) : parseInt(parseInt(data.total)/12)+1); number++) {
          //     table_items.push(
          //       <Pagination.Item key={number} active={number === page.index} onClick={()=> {setPage(oldValue => {return {...oldValue , index : number}})}}
          //           className='mx-2' style={{borderRadius: "20%" ,overflow: "hidden" , border : "1px solid #b7b7b7"}}
          //       >
          //         {number}
          //       </Pagination.Item>,
          //     );
          //   }
          // }
          setItems(table_items) ;
        }
      },
      onError(error) {
        
      },
    }
  );


   
  const { mutate : mutateUpdate, isLoading : isLoadingUpdate } = useMutation(
    ( userInfo) => deleteArticle(userInfo),
    {
      async onSuccess(data) {
        console.log(data);
        if(data && data.success) {
          setServices(oldValue => {
            return oldValue.filter(el => publireportage.id != el.id) ; 
          }) ; 
          setPublireportage(null) ; 
          handleClose() ; 
          
        }else { 
          
        }
      },
      onError(error) {
        
      },
    }
  );

  
  useEffect(()=> {
    mutateArticles({admin : true , filter : search ,  limit : 12 , offset : (page.index -1)*12 }) ; 
  } , []) ;

  useEffect(()=> {
    mutateArticles({admin : true ,filter : search ,  limit : 12 , offset : (page.index -1)*12 }) ; 
  } , [page.index]) ;

  

  const rechercher = () => {
    mutateArticles({admin : true ,filter : search ,  limit : 12 , offset : (page.index -1)*12 }) ; 
  }
  


  

  const notify = (message) => {
    
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000 , 
      fontSize : "12px" 
      });
  }

  const notify_success = (message) => {
    
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000
      });
  }


  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };

  
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (publireportage) => {
    setPublireportage(publireportage) ;
    setOpenAdd(true);
    console.log(publireportage);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);

    setNomService("") ; 
    setNomServiceAr("") ; 
    setNomServiceEn("") ; 
    setDescription("") ;  
    setDescriptionAr("") ;  
    setDescriptionEn("") ; 
    setPictureUrl("") ;
  };
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
  return (
    <div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox style={{width : "100%"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
          
          <MDBox>
            <Grid container spacing={1}>
              <Grid item xs={8} className="d-flex justify-content-end align-items-center">
                <MDInput style={{width : "100%" , background  : "white"}} label="Search"  onChange={e => setSearch(e.currentTarget.value)} />
              </Grid>
              <Grid item xs={2} className="d-flex justify-content-start align-items-center">
                <MDButton color="dark" style={{maxWidth : "100%" , overflow  : "hidden"}}   onClick={rechercher}  >Filter</MDButton>
              </Grid>
               <Grid item xs={2} className="d-flex justify-content-end align-items-center">
                <MDButton color="dark"   onClick={handleOpenAdd}  style={{maxWidth : "100%" , overflow  : "hidden"}}  >Ajouter</MDButton>
              </Grid> 
            </Grid>
            </MDBox>
            </Grid>
          </Grid>
          <Row style={{marginTop : "30px"}}> 
        <Col xs={12} style={{height : isMobileDevice() ? "70px" : ""}}>
              {isMobileDevice() && 
                <HorizontalScroll style={{overflowX : "auto"}}>
                  {categories.map((cat, index) => (
                  <div onClick={() => {
                    if(lang === "en") {
                      navigate("/en/categorie/"+cat.id) ;
                    }else if(lang == "ar") {
                      navigate("/ar/categorie/"+cat.id) ;
                    }else {
                      navigate("/fr/categorie/"+cat.id) ;
                    }
                    }} className={" px-4 ml-2 cat_item_in_list font-weight-600 d-flex justify-content-center align-items-center" } >
                    {cat.name}
                  </div>  
                      
                  ))}
                </HorizontalScroll>
              }
              {!isMobileDevice() &&
                <div >
                  <div  style={{display : 'inline-block'}} className=' ml-2 mb-3'>
                  </div>
                  {categories.map((cat, index) => (
                  <div key={index} style={{display : 'inline-block'}} className=' ml-2 mb-3'>
                    <div onClick={() => {
                        if(lang === "en") {
                          navigate("/en/categorie/"+cat.id) ;
                        }else if(lang == "ar") {
                          navigate("/ar/categorie/"+cat.id) ;
                        }else {
                          navigate("/fr/categorie/"+cat.id) ;
                        }
                    }}  className={" px-4 cat_item_in_list font-weight-600 d-flex justify-content-center align-items-center" } >
                    {cat.name}
                    </div>  
                  </div>
                      
                  ))}
                </div>
              }
            </Col>
        </Row>
        </MDBox>
      </MDBox>
      <MDBox>
        {/* <DataTable
          table={{ columns, rows }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={true}
        /> */}
        <Container className="p-4 pt-0">
          <div  style={{maxHeight : "65vh" }}>
            
          <Row className='justify-content-start ml-0 p-4'>
                    {services.length > 0 &&
                      <Col xs={12} className="d-flex justify-content-center align-items-center my-3">
                        {/* <Pagination>{items}</Pagination> */}
                        <Pagination count={(parseInt(page.totalItems)%12 == 0 ? parseInt(parseInt(page.totalItems)/12) : parseInt(parseInt(page.totalItems)/12)+1)} page={page.index} onChange={(event, value)=> {setPage(oldValue => {return {...oldValue , index : value}})}} />
                      </Col>
                        
                      }

                    {services.map((post, index) => (
                      <Col key={index} href={post.url} className="group col-12 col-md-6 col-lg-4  mb-5 "  initial="rest" whileHover="hover" animate="rest"
                        
                      >
                        <div style={{width : "90%"}}>
                          <Image
                            transition={{ duration: 0.3 }}
                            variants={postBackgroundSizeAnimation}
                            $imageSrc={post.image}
                          />
                        </div>
                        <div className='p-2 bg-white'  style={{ width : "90%" , marginLeft : "10%" , marginTop : "-30px"}}>
                        <div className='p-2 px-0 bg-white'>
                          <div className={ "w-100 d-flex justify-content-end align-items-center"}>
                              <MDBox color="dark" px={2}>
                                <Dropdown color="dark" align="start">
                                  <Dropdown.Toggle style={{background : "rgb(1 192 130)"  , boxShadow  : "none" , border : "none"}}  id="icon-dropdown">
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item  disabled={post.status} onClick={()=> {
                                      setPublireportage(post) ; 
                                      setOpen(true) ; 
                                    }}>Supprimer</Dropdown.Item>
                                    {/* <Dropdown.Item href="#action-4">Archiver</Dropdown.Item> */}
                                  </Dropdown.Menu>
                                </Dropdown>
                            </MDBox>
                          </div>  
                        </div>
                        <AuthorInfo style={{marginTop : "0"}}>
                          <div className='w-100 d-flex justify-content-between align-items-center' style={{ paddingTop: '0'}}>
                            <AuthorProfile style={{cursor : "pointer" , textDecoration : "underline" , color : "rgb(1 192 130)" , fontWeight : "bold"}}    onClick={() => {
                              if(lang === "en") {
                                navigate("/en/categorie/"+post.categorie_id) ;
                              }else if(lang == "ar") {
                                navigate("/ar/categorie/"+post.categorie_id) ;
                              }else {
                                navigate("/fr/categorie/"+post.categorie_id) ;
                              }
                              }}>{categories.filter(el => el.id === parseInt(post.categorie_id))[0].name }</AuthorProfile>
                            <AuthorProfile>{post.date}</AuthorProfile>
                          </div>
                        </AuthorInfo>
                        <TitleTw  onClick={() => {
                          if(lang === "en") {
                            navigate("/en/article/"+post.id) ;
                          }else if(lang == "ar") {
                            navigate("/ar/article/"+post.id) ;
                          }else {
                            navigate("/fr/article/"+post.id) ;
                          }
                          }} style={{cursor : "pointer" , textAlign : "center" , paddingBottom: '15px' , marginTop : "0"}}>{lang === "fr" ? post.reformulated_title : lang === "en" ? post.reformulated_title_en : post.reformulated_title_ar }</TitleTw>
                        {/* <DescriptionTw style={{maxHeight: "300px" , overflow: "hidden" , textOverflow: "ellipsis"}}>{lang === "fr" ? post.reformulated_description : lang === "en" ? post.reformulated_description_en : post.reformulated_description_ar}</DescriptionTw> */}
                        {/* <div className='w-100 d-flex align-items-center justify-content-center'>
                          <strong style={{width : "100%" , textAlign : "center" , fontSize : "13px"}}>
                          {lang === "fr" ? "La redaction " : lang === "en" ? " Writing " :  " التحرير"}
                            </strong>
                        </div> */}

                        </div>
                      </Col>
                    ))}
                    {services.length > 0 &&
                      <Col xs={12} className="d-flex justify-content-center align-items-center my-3">
                        {/* <Pagination>{items}</Pagination> */}
                        <Pagination count={(parseInt(page.totalItems)%12 == 0 ? parseInt(parseInt(page.totalItems)/12) : parseInt(parseInt(page.totalItems)/12)+1)} page={page.index} onChange={(event, value)=> {setPage(oldValue => {return {...oldValue , index : value}})}} />
                      </Col>
                        
                      }
                    {page.totalItems == 0 && 
                      <Col xs={12} className="d-flex justify-content-center align-items-center" style={{minHeigth : "70vh"}}>
                            <MDTypography variant="h6" color="grey">
                            Aucun Article
                            </MDTypography>
                        
                      </Col>
                    
                    }
                  </Row>
              
            </div>
        </Container>
      </MDBox>
      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <MDTypography variant="h6" color="error">
          Est-ce-que vous voulez vraiment supprimer cet article ?
          </MDTypography>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Confirmer</Button> */}
        <MDButton color="error"  onClick={() => {mutateUpdate({id :  publireportage.id}) ;}}>Confirmer</MDButton>
      </Modal.Footer>
    </Modal>

    <Modal
      show={openAdd}
      onHide={handleCloseAdd}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{zIndex : "1299"}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width :"100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
          <CardPub style={{maxWidth : "100%" , width : "100%" }}>
              <div 
                style={{width : "100%"  , height : "300px" , border : pictureUrl ? "" :  "3px dashed  black" , margin : "20px auto" , display : "flex" , 
                        justifyContent : "center" ,alignItems : "center" , cursor : "pointer" , overflow : "hidden"  , 
                        background : pictureUrl && pictureUrl != "" ? "url("+pictureUrl+")" : ""
                      }}
                className="bg-image"
                onClick={handleButtonClick}
                >
                <input  accept="image/*" ref={fileInputRef} onClick={handleFileChange}  onChange={handleFileChange} type="file" style={{display : "none"}}/>
                
                {/* {pictureUrl && pictureUrl != "" && <img style={{height : "100%" , width : "auto"}} src={pictureUrl} />} */}
                {(!pictureUrl || pictureUrl === "") && 
                  <Icon fontSize="large" color="dark">add_a_photo</Icon>
                }
              </div>
              <FormControl fullWidth style={{marginBottom :"15px"}}>
                  <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCategorie}
                  style={{height : "50px"}}
                  label="Categorie"
                  onChange={(event)=>{
                    console.log(event.target.value) ;
                    setSelectedCategorie(event.target.value)
                  }}
                >
                  {categories != null && categories.length > 0 &&
                    categories.map(el => {
                      return <MenuItem key={""+el.id} value={""+el.id} >{el.name}</MenuItem> ;
                    })
                  }
                </Select>
              </FormControl>
              <Divider></Divider>
              <MDInput style={{width : "100%"}} label="Titre Fr" value={nomService}  onChange={e => setNomService(e.currentTarget.value)}  />
              <MDInput type="text" style={{width : "100%" , marginTop : "20px"}} label="Description Fr"  multiline rows={4} value={description}  onChange={e => setDescription(e.currentTarget.value)}  />
              <Divider></Divider>
              <MDInput style={{width : "100%"}} label="Titre EN" value={nomServiceEn}  onChange={e => setNomServiceEn(e.currentTarget.value)}  />
              <MDInput type="text" style={{width : "100%" , marginTop : "20px"}} label="Description EN"  multiline rows={4} value={descriptionEn}  onChange={e => setDescriptionEn(e.currentTarget.value)}  />
              <Divider></Divider>
              <MDInput style={{width : "100%"}} label="Titre Ar" value={nomServiceAr}  onChange={e => setNomServiceAr(e.currentTarget.value)}  />
              <MDInput type="text" style={{width : "100%" , marginTop : "20px"}} label="Description Ar"  multiline rows={4} value={descriptionAr}  onChange={e => setDescriptionAr(e.currentTarget.value)}  />
              
          </CardPub>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleCloseAdd}>Confirmer</Button> */}
        <MDButton 
        color="dark"  
        onClick={ insertNewSecteur}
        >Confirmer</MDButton>
      </Modal.Footer>
    </Modal>
    
    
    <Modal
      show={ isLoadingServices || isLoadingUpdate}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body  style={{background : "transparent"}}>
        
      <Box sx={{ display: 'flex' }} className="justify-content-center align-items-center">
        <CircularProgress color='black' />
      </Box>
      </Modal.Body>
    </Modal>
    </div>
  );
}

export default List;
