import { authApi } from "./server";



export const getAdminIndicators = async () => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.get('admin/indicators');
    return response.data;
};

export const getWaitingPublireportages = async () => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.get('admin/waitingPubs');
    return response.data;
};

export const getALaUneEntreprises = async () => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.get('entreprises/aLaUneEntreprises');
    return response.data;
};


export const getALaUneEntreprisesList = async () => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.get('entreprises/aLaUneEntreprisesList');
    return response.data;
};


export const defineEntrepriseALaUne = async (userInfos) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('admin/defineEntrepriseALaUne' , userInfos);
    return response.data;
};


export const deleteEntrepriseALaUne = async (userInfos) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('admin/deleteEntrepriseALaUne' , userInfos);
    return response.data;
};




export const createAbonnement = async (userInfos) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('abonnements' , userInfos);
    return response.data;
};



export const getAbonnement = async () => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.get('abonnements');
    return response.data;
};


export const getServices = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('services' , userInfo);
    return response.data;
};



export const updateService = async (userinfo) => {
    let id = userinfo.id
    delete userinfo.id;
    console.log(id) ;
    console.log(userinfo) ;
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('services/update/'+id , userinfo);
    return response.data;
};



export const getSecteurs = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('secteurs' , userInfo);
    return response.data;
};


export const uploaderSercteur = async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('nom', userInfo.nom);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('secteurs/create' , formData);
    return response.data;
};

export const updateSecteur = async (userinfo) => {
    let id = userinfo.id
    delete userinfo.id;

    let toUpload = {} ; 

    if(userinfo.file) {
        const formData = new FormData();
        formData.append('image', userinfo.file);
        formData.append('nom', userinfo.nom);
        toUpload = formData ; 

    }else {
        toUpload = userinfo ; 
    }
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('secteurs/update/'+id , toUpload);
    return response.data;
};


export const activateSecteur = async (userinfo) => {
    let id = userinfo.id
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('secteurs/activate/'+id , {});
    return response.data;
};


export const desactivateSecteur = async (userinfo) => {
    let id = userinfo.id
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('secteurs/desactivate/'+id , {});
    return response.data;
};



export const getPartenaires = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('partenaires' , userInfo);
    return response.data;
};


export const uploaderPartenaire= async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('nom', userInfo.nom);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('partenaires/create' , formData);
    return response.data;
};

export const updatePartenaire = async (userinfo) => {
    let id = userinfo.id
    delete userinfo.id;

    let toUpload = {} ; 

    if(userinfo.file) {
        const formData = new FormData();
        formData.append('image', userinfo.file);
        formData.append('nom', userinfo.nom);
        toUpload = formData ; 

    }else {
        toUpload = userinfo ; 
    }
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('partenaires/update/'+id , toUpload);
    return response.data;
};




export const getInfos = async () => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    let id =  localStorage.getItem("id") !== null ?  localStorage.getItem("id") : null;
    const response = await authApi.get('infos/'+id , {});
    return response.data;
};

export const updateUserInfos = async (userinfo) => {
    let id = localStorage.getItem("id") ;

    if(id) {
        authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
        const response = await authApi.post('infos/update/'+id , userinfo);
        return response.data;

    }else {
        return {
            success : false 
        } ;
    }
};



export const updateUserEmail = async (userinfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('users/updateEmail/' , userinfo);
    return response.data;
    
};



export const updateUserPassword = async (userinfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('users/updatePassword/' , userinfo);
    return response.data;
    
};


export const getGroupes = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('groupes' , userInfo);
    return response.data;
};


export const uploaderGroupe= async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('nom', userInfo.nom);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('groupes/create' , formData);
    return response.data;
};

export const updateGroupe = async (userinfo) => {
    let id = userinfo.id
    delete userinfo.id;

    let toUpload = {} ; 

    if(userinfo.profile_pic) {
        const formData = new FormData();
        formData.append('profile_pic', userinfo.profile_pic);
        toUpload = formData ; 

    }else if(userinfo.cover_pic) {
        const formData = new FormData();
        formData.append('cover_pic', userinfo.cover_pic);
        toUpload = formData ; 

    }else {
        toUpload = userinfo ; 
    }
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('groupes/update/'+id , toUpload);
    return response.data;
};

export const getEntreprises = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('entreprises' , userInfo);
    return response.data;
};


export const uploaderEntreprise= async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('nom', userInfo.nom);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('entreprises/create' , formData);
    return response.data;
};

export const updateEntreprise = async (userinfo) => {
    let id = userinfo.id
    delete userinfo.id;

    let toUpload = {} ; 

    if(userinfo.file) {
        const formData = new FormData();
        formData.append('image', userinfo.file);
        formData.append('nom', userinfo.nom);
        toUpload = formData ; 

    }else {
        toUpload = userinfo ; 
    }
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('entreprises/update/'+id , toUpload);
    return response.data;
};



export const getPublireportages = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('pubs' , userInfo);
    return response.data;
};


export const uploaderPublireportage= async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('nom', userInfo.nom);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('pubs/create' , formData);
    return response.data;
};

export const updatePublireportage = async (userinfo) => {

    console.log("service") ; 
    console.log(userinfo) ;
    let id = userinfo.id
    delete userinfo.id;

    let toUpload = {} ; 

    if(userinfo.file) {
        const formData = new FormData();
        formData.append('image', userinfo.file);
        formData.append('nom', userinfo.nom);
        toUpload = formData ; 

    }else {
        toUpload = userinfo ; 
    }
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('pubs/update/'+id , toUpload);
    return response.data;
};



export const getBannieres = async (userInfo) => {
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('bannieres' , userInfo);
    return response.data;
};


export const uploaderBanniere= async (userInfo) => {
    console.log(userInfo) ;
    const formData = new FormData();
    formData.append('image', userInfo.file);
    formData.append('espace_id', userInfo.espace_id);
    formData.append('entreprise_id', userInfo.entreprise_id);
    formData.append('date_debut', userInfo.date_debut);
    formData.append('date_fin', userInfo.date_fin);
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('bannieres/create' , formData);
    return response.data;
};

export const updateBanniere = async (userinfo) => {
    console.log("service") ; 
    console.log(userinfo) ;

    let id = userinfo.id
    delete userinfo.id;

    let toUpload = {} ; 

    if(userinfo.file) {
        const formData = new FormData();
        formData.append('image', userinfo.file);
        toUpload = formData ; 

    }else {
        toUpload = userinfo ; 
    }
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('bannieres/update/'+id , toUpload);
    return response.data;
};



export const updateAbonnement = async (userinfo) => {

    let id = userinfo.id
    delete userinfo.id;
    
    authApi.defaults.headers.common['Authorization'] = "Bearer "+ localStorage.getItem("token");
    const response = await authApi.post('abonnements/update/'+id , userinfo);
    return response.data;
};


