import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useEffect } from "react";
import { Row , Col , Pagination, Button, Badge  } from 'react-bootstrap';
import AnchorLink from "react-anchor-link-smooth-scroll";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { getSecteurs } from "services/secteursService";
import { useMutation } from "@tanstack/react-query";
import { getServices } from "services/servicesService";
import { BASE_URL_Image } from "services/server";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import MDButton from "components/MDButton";
import logo from "assets/images/proximity_logo.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
    import PerfectScrollbar from 'react-perfect-scrollbar'

import Hammer from 'hammerjs';
// Import Swiper styles
import 'swiper/css';

import HorizontalScroll from "react-scroll-horizontal";
import "./serviceSlider.css" ; 
import { Close } from '@mui/icons-material';
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { getArticles } from 'services/blogService';
import { Box, CircularProgress, Divider, Switch } from '@mui/material';
const MainContainer = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto `;


const CheckBoxList = () => {
  const [categories, setCategories] = useState({
    user: [{"name" : "abonnement" , checked : false , subItems : []} , {"name" : "preferences" , checked : false , subItems : []} ],
    localisation: [{"name" : "manuelle" , checked : false , subItems : []} , {"name" : "automatique" , checked : false , subItems : []}],
    paiement: {
      online: false,
      sousElements: ["abonnement", "preferences"],
    },
    commande: ["pickup", "delivery", "reservation", "return", "refund"],
    magazin: ["categories dynamique", "rayons", "template"],
  });
  const cat = {"name" : "" , checked : false , subItems : []} ; 
  const [abonnement, setabonnement] = useState(false) ; 
  const [preferences, setpreferences] = useState(false) ; 
  const [manuelle, setmanuelle] = useState(false) ; 
  const [automatique, setautomatique] = useState(false) ; 
  const [online, setonline] = useState(false) ; 
  const [Paypal, setPaypal] = useState(false) ; 
  const [Stripe, setStripe] = useState(false) ; 
  const [pickup, setpickup] = useState(false) ; 
  const [delivery, setdelivery] = useState(false) ; 
  const [reservation, setreservation] = useState(false) ; 
  const [retour, setretour] = useState(false) ;  
  const [refund, setrefund] = useState(false) ;  
  const [catdynamic, setcatdynamic] = useState(false) ;  
  const [rayons, setrayons] = useState(false) ;  
  const [template, settemplate] = useState(false) ;  

  const [generate, setgenerate] = useState(false) ; 
  
  const [operation1, setoperation1] = useState(false) ;  

  const [operation2, setoperation2] = useState(false) ;  

  const [operation3, setoperation3] = useState(false) ;  

  const handle_generate = () => {
    setgenerate(true) ;
    setTimeout(() => {
      setoperation1(true) ;
    }, 3000);
  }
  const handle_regenerate = () => {
    setgenerate(false) ;
    setoperation1(false) ;
    setoperation2(false) ;
    setoperation3(false) ;
  }

  useEffect(()=>{
    if(operation1) {
      setTimeout(() => {
        setoperation2(true) ;
      }, 3000);
    }
  } , [operation1]) ;

  useEffect(()=>{
    if(operation2) {
      setTimeout(() => {
        setoperation3(true) ;
      }, 3000);
    }
  } , [operation2]) ;

  return (
    <div  style={{ width: "100%" , height : "100%" , margin : 0 , padding : 0 ,  background : "#3c414b"}}>

      <AnimationRevealPage>
      <MainContainer>
        <Content>
          <div className='row w-100 mx-auto justify-content-center' style={{padding : "30px" , borderRadius : "20px" , overflow : "hidden" , backgroundColor : "#dde1e7"}}>
              <div className='col-12 d-flex justify-content-center align-items-center '>
              <img alt= "" src={logo} style={{height : "auto" , width : "150px"}} />
              </div>
              <div className='col-12 d-flex justify-content-center align-items-center mb-3 title ' style={{fontSize : "24px" , fontWeight : "bold"}}>
                  SPL E-Commerce Proximity Generator
              </div>
              <div className='col-12 col-md-3 row ml-0 p-5' style={{margin: "10px" , borderRadius: "20px" ,overflow: "hidden" ,background: "#ebeef1"}} >
                  <div className='col-12 d-flex justify-content-start align-items-start categorietitle'>
                      User
                  </div>
                  <div className='col-12 categorieitemslist h-100  p-0'>
                    <MDBox display="flex" alignItems="center" mt={1} ml={0}>
                      <Switch checked={abonnement} onChange={()=>{setabonnement(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setabonnement(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        style={{color : abonnement ? "black" :  "#7b809a"}}
                      >
                        &nbsp;&nbsp;Abonnement
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={preferences} onChange={()=>{setpreferences(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setpreferences(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Preferences
                      </MDTypography>
                    </MDBox> 
                    
                  </div>
              </div>
              
              <div className='col-12 col-md-3 row ml-0 p-5' style={{margin: "10px" , borderRadius: "20px" ,overflow: "hidden" ,background: "#ebeef1"}}>
                  <div className='col-12 d-flex justify-content-start align-items-start categorietitle'>
                    Location
                  </div>
                  <div className='col-12 categorieitemslist h-100  p-0'>
                    <MDBox display="flex" alignItems="center" mt={1} ml={0}>
                      <Switch checked={manuelle} onChange={()=>{setmanuelle(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setmanuelle(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Manual
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={automatique} onChange={()=>{setautomatique(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setautomatique(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Automatic
                      </MDTypography>
                    </MDBox> 
                    
                  </div>
              </div>
              
              <div className='col-12 col-md-3 row ml-0 p-5' style={{margin: "10px" , borderRadius: "20px" ,overflow: "hidden" ,background: "#ebeef1"}}>
                  <div className='col-12 d-flex justify-content-start align-items-start categorietitle'>
                      Store
                  </div>
                  <div className='col-12 categorieitemslist h-100  p-0 p-0'>
                    <MDBox display="flex" alignItems="center" mt={1} ml={0}>
                      <Switch checked={catdynamic} onChange={()=>{setcatdynamic(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setcatdynamic(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;dynamic categories
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={rayons} onChange={()=>{setrayons(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setrayons(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;rayons
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={template} onChange={()=>{settemplate(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{settemplate(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Template
                      </MDTypography>
                    </MDBox> 
                    
                  </div>
              </div>
              
              <div className='col-12 col-md-3 row ml-0 p-5' style={{margin: "10px" , borderRadius: "20px" ,overflow: "hidden" ,background: "#ebeef1"}}>
                  <div className='col-12 d-flex justify-content-start align-items-start categorietitle '>
                      Payment
                  </div>
                  <div className='col-12 categorieitemslist h-100  p-0'>
                    <MDBox display="flex" alignItems="center" mt={1} ml={0}>
                      <Switch checked={online} onChange={()=>{setonline(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setonline(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Online payment
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={5}>
                      <Switch checked={Paypal} onChange={()=>{setPaypal(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setPaypal(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Paypal
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={5}>
                      <Switch checked={Stripe} onChange={()=>{setStripe(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setStripe(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Stripe
                      </MDTypography>
                    </MDBox> 
                    
                  </div>
              </div>
              
              <div className='col-12  col-md-3 row  ml-0 p-5' style={{margin: "10px" , borderRadius: "20px" ,overflow: "hidden" ,background: "#ebeef1"}}>
                  <div className='col-12 d-flex justify-content-start align-items-start categorietitle'>
                      Order
                  </div>
                  <div className='col-12 categorieitemslist h-100  p-0'>
                    <MDBox display="flex" alignItems="center" mt={1} ml={0}>
                      <Switch checked={pickup} onChange={()=>{setpickup(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setpickup(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Pickup
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={delivery} onChange={()=>{setdelivery(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setdelivery(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Delivery
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={reservation} onChange={()=>{setreservation(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setreservation(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Reservation
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={retour} onChange={()=>{setretour(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setretour(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Return
                      </MDTypography>
                    </MDBox> 
                    <MDBox display="flex" alignItems="center"  mt={1} ml={0}>
                      <Switch checked={refund} onChange={()=>{setrefund(value => !value )}} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={()=>{setrefund(value => !value )}}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Refund
                      </MDTypography>
                    </MDBox> 
                    
                  </div>
              </div>
              
              
              <div className='col-12 row w-100 ml-0 p-5 pt-0'>
                
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="dark" fullWidth onClick={handle_generate}>
                  Generate
                </MDButton>
              </MDBox>
              </div>
          </div>

        </Content>
        
        <Modal
          show={generate}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body  style={{background : "transparent"}}>
            <div className='row w-100 ml-0'>
              
            <div className='col-12' style={{fontSize : "27px" , fontWeight : "bold" , textAlign : "center" }}>
                Generate Product
              </div>
              <div className='col-12  mb-2'>
                <Divider></Divider>
              </div>
              
              <div className='col-2'>
              <Box sx={{ display: 'flex' }} className="h-100 w-100 justify-content-center align-items-center"  style={operation1 ? {display : "none"} : {}}>
                <CircularProgress color={ operation1 ? "success" : "black"} size={20} />
              </Box>
              </div>
              <div className='col-10' style={{fontSize : "17px" , color : operation1 ? "green" : "black"}}>
                Generate config files
              </div>

              <div className='col-2'>
                <Box sx={{ display: 'flex' }} className="h-100 w-100 justify-content-center align-items-center"  style={operation2 ? {display : "none"} : {}}>
                  <CircularProgress color={ operation2 ? "success" : operation1 ? "black" : "inherit"} size={20} style={operation1 ? {} : {display : "none"}} />
                </Box>
              </div>
              <div className='col-10' style={{fontSize : "17px" , color : operation2 ? "green" : operation1 ? "black" : "gray"}}>
                Generate models files 
              </div>

              <div className='col-2'>
                <Box sx={{ display: 'flex' }} className="h-100 w-100 justify-content-center align-items-center"  style={operation3 ? {display : "none"} : {}}>
                  <CircularProgress color={ operation3 ? "success" : operation2 ? "black" : "inherit"} size={20} style={operation2 ? {} : {display : "none"}} />
                </Box>
              </div>
              <div className='col-10' style={{fontSize : "17px" , color : operation3 ? "green" : operation2 ? "black" : "gray"}}>
                Integration concerned modules
              </div>

              <div className='col-12  mt-2'>
                <Divider></Divider>
              </div>

              <div className='col-6 justify-content-center align-items-center'  style={operation3 ? {display : "flex"} : {display : "none"}}>
                <MDBox >
                  <MDButton variant="gradient" color="dark" fullWidth onClick={handle_regenerate}>
                    Generate another Product
                  </MDButton>
                </MDBox>
              </div>
              <div className='col-6 justify-content-center align-items-center'  style={operation3 ? {display : "flex"} : {display : "none"}}>
                <MDBox >
                  <MDButton variant="gradient" color="success" fullWidth onClick={()=> {
                    window.open("");
                  }}>
                    Open generated product folder
                  </MDButton>
                </MDBox>
              </div>


            </div>
            
          
          </Modal.Body>
        </Modal>

      
        </MainContainer>
        </AnimationRevealPage>
    </div>

      
  );
};

export default CheckBoxList;
