import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "fr",
}

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeStoreLang: (state, action) => {
        console.log(action) ; 
        console.log("lang reducer") ;
        state.value = action.payload
        
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeStoreLang } = langSlice.actions

export default langSlice.reducer