import { useState, useEffect } from "react";

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// react-router components
import { Routes, Route, Navigate, useLocation , Redirect } from "react-router-dom";


// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";

// Material Dashboard 2 React contexts
import { useMaterialUIController} from "context";

// Images
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import FirstLayout from "FirstLayout";
import FirstLayoutAR from "FirstLayoutAR";
import FirstLayoutEN from "FirstLayoutEN";
import AdminnLayout from "AdminnLayout";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';


import Acceuil from "OurPages/OurComponents/Home/Acceuil";
import CheckBoxList from "OurPages/OurComponents/Home/CheckBoxList";

import Categorie from "OurPages/OurComponents/Home/Categorie";
import Article from "OurPages/OurComponents/Home/Article";
import SignIn from "layouts/authentication/sign-in";
import GServiceList from "layoutsGroupes/ServiceList";

export default function App() {
  
  const queryClient = new QueryClient();
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
  
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const { pathname } = useLocation();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  
  useEffect(() => {
    let token = localStorage.getItem("token") ; 
    let role = localStorage.getItem("role") ; 
    console.log(role) ; 
    if(token != null && token !== "" && role != null && role !== "") {
      setUserRole(role) ; 
    }else {
      setUserRole(null) ; 
    }
  }, [localStorage.getItem("role")]);

  
    
  return (
    <ThemeProvider theme={theme}>
      
      <QueryClientProvider client={queryClient}>

        <CssBaseline />
        
        <Routes>
          {/* <Route path="/" element={ <CheckBoxList /> }/> */}
          
          <Route
            path="/fr"
              element={<FirstLayout />}
          >
            {/* <Route path="/" element={ <Home /> }/> */}
            <Route path="/fr/" element={ <Acceuil /> }/>
            <Route path="/fr/categorie/:id" element={<Categorie />} />
            <Route path="/fr/article/:id" element={<Article />} />
          </Route>

          <Route
            path="/en"
              element={<FirstLayoutEN />}
          >
            {/* <Route path="/" element={ <Home /> }/> */}
            <Route path="/en/" element={ <Acceuil /> }/>
            <Route path="/en/categorie/:id" element={<Categorie />} />
            <Route path="/en/article/:id" element={<Article />} />
          </Route>
          
          <Route
            path="/ar"
              element={<FirstLayoutAR />}
          >
            {/* <Route path="/" element={ <Home /> }/> */}
            <Route path="/ar/" element={ <Acceuil /> }/>
            <Route path="/ar/categorie/:id" element={<Categorie />} />
            <Route path="/ar/article/:id" element={<Article />} />
          </Route>
          
          <Route path="/adminlogin" element={<SignIn />} />

          <Route
            path="/admin"
            element={localStorage.getItem("role") === "admin" ? <AdminnLayout /> : <Navigate to="/" /> }
            // use guard to protect admin route
          >
            
            <Route path="/admin" element={<GServiceList />} />
          
          </Route>

          
          <Route
            path="/*"
            element={<Navigate to="/fr" />}
          />
        </Routes>

        <ToastContainer style={{fontSize : "13px"}}/>
      </QueryClientProvider>

      
    </ThemeProvider>
   );
}
