import { authApi } from "./server";



export const getServices = async (userInfo) => {
    const response = await authApi.post('services' , userInfo);
    return response.data;
};



