// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import List from "layoutsGroupes/ServiceList/components/List";

function Dashboard() {

  return (
      <MDBox py={3} style={{marginTop : "70px" , minHeight : "calc(100vh - 70px )"}}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <List />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
  );
}

export default Dashboard;
