import { Outlet, useNavigate } from 'react-router-dom';

import Navbar from "./OurPages/OurComponents/Home/navbarAdminn";
import "./OurPages/Home.css" ; 

import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "./components/headers/light.js";
import { useEffect } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MDTypography from 'components/MDTypography';


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
function AdminnLayout() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }, []);

      
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    console.log(open) ;
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };
  
  const navigate = useNavigate();

  const categories = [
    {id : 1  , name :"Politique"} ,
    {id : 2  , name :"Economie"} ,
    {id : 3  , name :"International"} ,
    {id : 4  , name :"Sport"} ,
    {id : 5  , name :"Vidéos"} ,
    {id : 6  , name :"Société"} ,
    {id : 7  , name :"Consommation"} ,
    {id : 8  , name :"Culture, Médias, Technologies"} 
]

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className='w-100 p-3 d-flex justify-content-center align-items-center' style={{background : "#D2D8DD"}}>
        <MDTypography style={{color : "black"}}>Catégories</MDTypography>
      </div>
      <List  className='sidebarCatList mt-3'>
        {categories.map((text, index) => (
          <ListItem key={text.id} disablePadding onClick={() => {navigate("/categorie/"+text.id)}}>
            <ListItemButton>
              <ListItemText primary={text.name} style={{fontSize : "12px"}} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <>
    <React.Fragment>
          <Drawer
            anchor={'left'}
            open={state}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
          <Navbar onOpenSideBar={(value) => {
            console.log(value) ;
            setState(value) ;
            }}></Navbar>
            <div style={{background : "#D2D8DD"}}>
            <Outlet />
            </div>
        </React.Fragment>
    </>
  );
}

export default AdminnLayout;